// src/App.js - Enhanced with better scene setup and performance optimizations
import React, { Suspense, lazy, useState, useEffect } from 'react';
import { Canvas } from '@react-three/fiber';
import { Physics } from '@react-three/cannon';
import { Environment, OrbitControls, PerspectiveCamera } from '@react-three/drei';
import EmailModal from './components/EmailModal'; 
import Logo from './components/ui/Logo';
// CSS
import './App.css';

// Lazy load the heavy 3D components
const NeuralNetwork = lazy(() => import('./components/neural-network/NeuralNetwork'));
// const LetterNetwork = lazy(() => import('./components/letter-network/LetterNetwork'));

// Simple loading spinner component
const LoadingSpinner = () => (
  <div className="loading-spinner">
    <div className="spinner"></div>
    <p>Loading visualization...</p>
  </div>
);

/**
 * Enhanced main App component with improved 3D scene setup and performance
 */
function App() {
  // Detect device capabilities for performance optimizations
  const [deviceSettings, setDeviceSettings] = useState({
    isLowPower: false,
    dpr: [1, 2],
  });

  const [isModalOpen, setIsModalOpen] = useState(false);
  
  // Detect device performance on component mount
  useEffect(() => {
    const detectDevice = () => {
      // Check if it's a mobile device
      const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
      
      // Check for hardware capabilities
      const isLowPower = isMobile || 
                         (navigator.hardwareConcurrency && navigator.hardwareConcurrency < 4) ||
                         (navigator.deviceMemory && navigator.deviceMemory < 4) ||
                         window.innerWidth < 768;
      
      // Calculate optimal pixel density ratio
      const optimalDpr = isLowPower ? 
                         1 : 
                         [1, Math.min(window.devicePixelRatio, 2)];
      
      setDeviceSettings({
        isLowPower,
        dpr: optimalDpr
      });
    };
    
    detectDevice();
    
    // Update settings on window resize
    window.addEventListener('resize', detectDevice);
    return () => window.removeEventListener('resize', detectDevice);
  }, []);




  return (
    <div className="App">

      
      {/* Neural Network Section - Now lazy loaded with device optimizations */}
      <div className="neural-network-container"> 
        <Suspense fallback={<LoadingSpinner />}>
          <Canvas 
            dpr={deviceSettings.dpr} 
            camera={{ position: [0, 0, 12], fov: 60 }}
            performance={{ min: 0.5 }}
          >
            <color attach="background" args={['#0b1222']} />
            <ambientLight intensity={0.2} />
            <pointLight position={[10, 10, 10]} intensity={0.4} />
            <spotLight 
              position={[0, 5, 15]} 
              angle={0.3} 
              penumbra={1} 
              intensity={0.6} 
              castShadow={!deviceSettings.isLowPower}
            />
            <OrbitControls 
              enableZoom={false} 
              enablePan={false}
              rotateSpeed={0.3}
              maxPolarAngle={Math.PI / 2}
              minPolarAngle={Math.PI / 3}
            />
            <Physics>
              <NeuralNetwork isLowPower={deviceSettings.isLowPower} />
            </Physics>
            <Environment preset="city" />
          </Canvas>
        </Suspense>
      </div>
      

      {/* Updated content section with button and modal */}
      <div className="content">
        <h1>Welcome to Spoerico</h1>
        <p>AI Software Implementation for Your Business</p>
        <br></br>
        <br></br>
        <button onClick={() => setIsModalOpen(true)} className="schedule-link">
          Schedule a Consultation
        </button>
      </div>

      {/* Render the modal */}
      <EmailModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />

      
      {/* Letter Network Section - Also lazy loaded with device optimizations 
      <div className="letter-network-container">
        <Suspense fallback={<LoadingSpinner />}>
          <Canvas dpr={deviceSettings.dpr}>
            <PerspectiveCamera makeDefault position={[0, 0, 5]} fov={60} />
            <color attach="background" args={['#f8f9fa']} />
            <ambientLight intensity={0.5} />
            <spotLight position={[0, 5, 5]} angle={0.5} intensity={0.8} />
            <LetterNetwork isLowPower={deviceSettings.isLowPower} />
          </Canvas>
        </Suspense>
      </div>
      */}
      
      {/* Footer */}
      <footer className="imprint">
        <div className="footer-container">
          <div className="imprint-details">
            <p>Spoerico GmbH</p>
            <p>Hermann-Löns-Straße 5</p>
            <p>57250 Netphen</p>
            <p>GERMANY</p>
            <p>+49 171 5395666, jan@spoerer.me</p>
          </div>
          <div className="logo-container">
            <Logo className="footer-logo" />
          </div>
        </div>
      </footer>
    </div>
  );
}

export default App;