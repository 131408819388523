// src/components/EmailModal.jsx
import React, { useEffect, useRef } from 'react';
import './EmailModal.css';

const EmailModal = ({ isOpen, onClose }) => {
  const modalRef = useRef(null);

  // Close modal with ESC key
  useEffect(() => {
    const handleEsc = (event) => {
      if (event.key === 'Escape') {
        onClose();
      }
    };
    if (isOpen) {
      window.addEventListener('keydown', handleEsc);
    }
    return () => {
      window.removeEventListener('keydown', handleEsc);
    };
  }, [isOpen, onClose]);

  // Focus modal when it opens
  useEffect(() => {
    if (isOpen && modalRef.current) {
      modalRef.current.focus();
    }
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <div className={`modal-overlay ${isOpen ? 'open' : ''}`} onClick={onClose}>
      <div
        className={`modal-content ${isOpen ? 'open' : ''}`}
        onClick={(e) => e.stopPropagation()}
        tabIndex={-1}
        ref={modalRef}
      >
        <button className="modal-close" onClick={onClose}>
          ×
        </button>
        <p>
          I am fully booked, but happy to grab a coffee. Text me here: <a href="mailto:jan@spoerer.me">jan@spoerer.me</a>
          
        </p>
      </div>
    </div>
  );
};

export default EmailModal;