// Updated Logo.jsx with adjusted triangle positions and sizes
import React from 'react';

/**
 * SVG Logo component for Spoerico with right-pointing outlined triangles
 */
export default function Logo({ className }) {
  return (
    <svg 
      className={className}
      width="320" 
      height="60" 
      viewBox="0 0 320 60" 
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="xMinYMid meet"
    >
      {/* Main "Spoerico" text */}
      <text 
        x="5" 
        y="40" 
        fontSize="40" 
        fontFamily="sans-serif" 
        fontWeight="bold" 
        fill="#0033cc"
      >
        Spoerico
      </text>
      
      {/* Right side vertical text */}
      <text 
        x="230" 
        y="20" 
        fontSize="12" 
        fontFamily="sans-serif" 
        fontWeight="bold" 
        fill="#0033cc"
      >
        Finance
      </text>
      <text 
        x="230" 
        y="35" 
        fontSize="12" 
        fontFamily="sans-serif" 
        fontWeight="bold" 
        fill="#0033cc"
      >
        Data
      </text>
      <text 
        x="230" 
        y="50" 
        fontSize="12" 
        fontFamily="sans-serif" 
        fontWeight="bold" 
        fill="#0033cc"
      >
        Software
      </text>
        
      <polygon 
        points="121.4,9.4 121.4,17.4 129.4,13.4" 
        fill="#f5f6fa"
        stroke="#f5f6fa"
        strokeWidth="2.1"
        strokeLinejoin="round"
        />


      {/* Pink triangle above "i" in Spoerico - positioned to cover the dot */}
      <polygon 
        points="121,9 121,17 129,13" 
        fill="#f5f6fa"
        stroke="#ff00ff"
        strokeWidth="2.1"
        strokeLinejoin="round"
        />

        {/* Pink larger right-pointing triangle before Finance - moved up and left, made larger */}
        <polygon 
            points="198,16 198,41 218,28.5" 
            fill="none"
            stroke="#ff00ff"
            strokeWidth="3"
            strokeLinejoin="round"
        />
    </svg>
  );
}